<template>
  <div>
    <header>
      <el-button
        icon="el-icon-plus"
        size="small"
        type="primary"
        @click="openDialog('add')"
        >新增配置</el-button
      >
    </header>
    <main>
      <el-scrollbar style="height: 68vh">
        <el-table
          v-loading="loading"
          :header-cell-style="tableColor"
          :data="tableData"
          size="small"
          stript
          style="width: 100%"
        >
          <el-table-column prop="title" label="编码" width="180">
          </el-table-column>
          <el-table-column prop="msg" label="说明" width="180">
          </el-table-column>
          <el-table-column prop="substance" label="值"> </el-table-column>
          <el-table-column label="操作" width="180" align="center">
            <template #default="{ row }">
              <el-button
                size="small"
                type="text"
                @click="openDialog('edit', row)"
                >编辑</el-button
              >
              <el-button
                size="small"
                type="text"
                style="color: red"
                @click="deleteItem(row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-scrollbar>
      <el-pagination
        background
        style="float: right"
        @size-change="handleChange('page_long', $event)"
        @current-change="handleChange('page', $event)"
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </main>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="`${title}配置`"
      width="60%"
      :close-on-click-modal="false"
    >
      <el-form
        :model="system"
        :rules="rules"
        ref="ruleForm"
        label-position="left"
        label-width="50px"
        class="demo-ruleForm"
      >
        <el-form-item label="编码" prop="name">
          <el-input v-model="system.title" placeholder="请输入编码"></el-input>
        </el-form-item>
        <el-form-item label="值" prop="region">
          <el-input v-model="system.substance" placeholder="请输入值">
          </el-input>
        </el-form-item>
        <el-form-item label="备注" prop="region">
          <el-input v-model="system.msg" placeholder="请输入备注"> </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="updateForm">{{
          title
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getConfig, addConfig, deleteConfig } from "@/api/config/index.js";
export default {
  name: "global",
  data() {
    return {
      listQuery: {
        keyword: "",
        page: 1,
        page_long: 10,
      },
      total: 100,
      tableData: [],
      system: {},
      dialogVisible: false,
      rules: {
        title: [{ required: true, message: "请输入编码", trigger: "blur" }],
        substance: [
          { required: true, message: "请输入编码值", trigger: "blur" },
        ],
        msg: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },
      title: "新增",
      loading: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleChange(prop, $event) {
      this.listQuery = {
        ...this.listQuery,
        [prop]: $event,
      };
      this.getList();
    },
    tableColor() {
      return { background: "#f2f2f2" };
    },
    openDialog(type, row = {}) {
      this.dialogVisible = true;
      if (type === "add") {
        this.title = "新增";
        this.system = {};
      } else {
        this.title = "更改";
        this.system = {
          ...row,
        };
      }
    },
    async getList() {
      this.loading = true;
      const { data, status } = await getConfig(this.listQuery);
      this.tableData = data;
      this.loading = false;
    },
    async updateForm() {
      try {
        const result = await addConfig(this.system);
        this.$message.success("添加成功！");
        this.getList();
        this.dialogVisible = false;
      } catch (error) {
        this.$message.error(error);
      }
    },
    deleteItem(id) {
      this.$confirm("是否确认删除该配置?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteConfig(id)
            .then(() => {
              this.$message.success("删除成功！");
              this.getList();
            })
            .catch((err) => {
              this.$message.error(err);
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="less">
header {
  margin: 12px 0;
}
</style>
